import Play from "../../icons/Play";
import { LessonCardOptions } from "./LessonCard.d";
import "./LessonCard.scss";
import { Link } from "react-router-dom";

const _getLockedClassName = (extraCoursesNeeded: boolean | undefined) => {
  switch (extraCoursesNeeded) {
    case true:
      return "locked";
    case false:
      return "unlocked";
    default:
      return "";
  }
};

/**
 * Lesson Card component containing an illustration, a title, a subtitle, permitting user to reach its lesson
 *
 * @component
 * @property {Array<string>} extra_courses Specify needed/owned extra courses
 * @property {boolean} extra_courses_needed Specify if extra course is needed
 * @property {string} illustration Picture URL
 * @property {string} title Lesson Card title
 * @property {string} subtitle Lesson Card additional text
 * @property {string} link Link of the option
 * @property {string} width Lesson Card maximum width (default: "300px")
 */
const LessonCard = ({
  extra_courses,
  extra_courses_needed,
  illustration,
  title,
  subtitle,
  link,
  width = "300px",
}: LessonCardOptions): JSX.Element => (
  <div
    className={`lesson-card ${_getLockedClassName(extra_courses_needed)}`}
    style={{
      width,
    }}
  >
    <div className="image-section">
      <Link to={link}>
        {extra_courses_needed !== null &&
          (extra_courses_needed ? (
            <img
              src="/images/extra-courses/locked.png"
              alt="locked"
              className="locked"
            />
          ) : (
            <img
              src="/images/extra-courses/unlocked.png"
              alt="unlocked"
              className="unlocked"
            />
          ))}
        <img src={illustration} alt="Lesson illustration" />
        <div className="player-section">
          <div>
            <Play classes="" color="white" />
          </div>
        </div>
      </Link>
    </div>
    <div>
      <div
        className={`title ${extra_courses_needed !== null ? "red-font" : ""}`}
      >
        <b>{title}</b>
      </div>
      <div className="subtitle">{subtitle}</div>
    </div>
  </div>
);

export default LessonCard;
