// import ApiService from "../../services/ApiService";
import "./Promotion.scss";
import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

type PromotionObject = {
  name: string;
  schedule: string[];
  description: string;
  buttonUrl: string;
  buttonText: string;
};

const promoData: PromotionObject = {
  name: "Promo TBL",
  schedule: ["2025-03-18T09:00:00.000Z", "2025-03-30T23:00:00.000Z"],
  description:
    "Prenez dès maintenant votre place pour rencontrer Joëlle le 30 mars à Paris",
  buttonUrl:
    "https://www.eventbrite.fr/e/inscription-atelier-the-belly-lab-paris-1226386829019?aff=ebdsshcopyurl&utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=organizer-profile&utm-share-source=organizer-profile",
  buttonText: "Prendre ma place",
};

const Promotion = () => {
  const [promoIsVisible, setPromoIsVisible] = useState(false);
  const [showPromoCalendar, setShowPromoCalendar] = useState(false);

  useEffect(() => {
    const _findTemporaryPromotion = () => {
      const now = new Date();

      const start = new Date(promoData.schedule[0]);
      const end = new Date(promoData.schedule[1]);
      return start <= now && now <= end ? promoData : null;
    };
    setShowPromoCalendar(!!_findTemporaryPromotion());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const coursesAnswer = await ApiService.get(
        //   "e-learning/courses/?sort=activity"
        // );
        // const userCoursesAnswer = await ApiService.get("users/courses");
        // const promo = coursesAnswer.data.find(
        //   (course: any) => course.name.includes(promoData.name)
        //   // (course: any) => course.name === "Abonnement : Belly Premium"
        // );
        // setPromoIsVisible(!userCoursesAnswer.data.includes(promo.id));
        setPromoIsVisible(true);
      } catch (err) {
        toast.error(
          "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard."
        );
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {showPromoCalendar && promoIsVisible && (
        <div id="promotion">
          <div>
            <p>
              <b>{promoData.description}</b>
            </p>
          </div>
          <button>
            <a href={promoData.buttonUrl}>{promoData.buttonText}</a>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default Promotion;
