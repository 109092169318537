import ApiService from "../../services/ApiService";
import { SyntheticEvent } from "react";

export const getNextLesson = (lessonId: string, lessonsId: string[]) => {
  const index = lessonsId.indexOf(lessonId);
  return index === lessonsId.length ? null : lessonsId[index + 1];
};

export const findCourseColor = (course: any) => {
  const primaryColor = course?.course_meta?.find(
    (elem: any) => elem.meta_key === "primaryColor"
  )?.meta_value;
  const secondaryColor = course?.course_meta?.find(
    (elem: any) => elem.meta_key === "secondaryColor"
  )?.meta_value;

  return {
    primaryColor: primaryColor ?? "#cff5ec",
    secondaryColor: secondaryColor ?? "#f6abae",
  };
};

export const getTextColor = (color: string) =>
  ["white", "#cff5ec"].includes(color) ? "black" : "white";

export const sendEnded = (
  event: SyntheticEvent<HTMLVideoElement, Event>,
  lesson_id: string,
  setIsEnded: Function
) => {
  if (event.currentTarget.currentTime >= event.currentTarget.duration - 30) {
    setIsEnded(lesson_id);
    console.log("Saving finished lesson");
    ApiService.post(`e-learning/lesson-activity/${lesson_id}`, {})
      .then(() => console.log("Saved lesson as finished"))
      .catch((err) =>
        console.error("An error occurred while saving lesson as finished", err)
      );
  }
};

export const findFirstPurchaseUrl = (
  extraCourses: Array<any>
): string | undefined =>
  extraCourses.find((element) => !!element.purchase_url)?.purchase_url;
